import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from "axios";
import { useRestapi } from "./restapi";
import {Cookies, Notify} from "quasar";
import { useRouter} from "vue-router";
import { ref } from "vue";


export const useRegionStore = defineStore('region', () => {
  const org = ref(null);
  const api_instance = ref(null);
  const api_region = ref(null);
  const session_promise = ref(null);
  const org_promise = ref(null);
  const activity = ref(null);
  const course = ref(null);
  const router = useRouter();

  const RestAPI = useRestapi()
  axios.defaults.withCredentials = true;

  console.log(import.meta.env.VITE_API_URL);

  const default_api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  default_api.interceptors.response.use(RestAPI.intercept, RestAPI.error_intercept);

  const no_intercept = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const session = async () => {
    try {
      const response = await no_intercept.get('/user_session');
      if (response.status === 200) {
        if (response.data.session.organization) {
          org.value = response.data.session.organization;
        }
        return {
          user: response.data.session.user,
          organization: response.data.session.organization
        };
      }
      if (response.status === 401) {
        Cookies.remove('session_id');
        localStorage.removeItem('logged_in');
        org.value = null;
        router.push('/login');
      }
    } catch (error) {
      if(error && error.response && error.response.status === 401) {
        Cookies.remove('session_id');
        localStorage.removeItem('logged_in');
        org.value = null;
        router.push('/login');
      }
      console.log(error);
      /*
      throw error;
      */
    }
  };

  const api = async () => {
    const s = await session_promise.value;
    const o = await org_promise.value;
    if (!org.value) {
      router.push('/signup');
      return;
    }
    if (!api_instance.value || org.value.region !== api_region.value) {
      api_region.value = org.value.region;
      api_instance.value = axios.create({
        baseURL: `https://api.${org.value.region}.${import.meta.env.VITE_STAGE}.chartflow.io`,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      api_instance.value.interceptors.response.use(RestAPI.intercept, RestAPI.error_intercept);
    }
    return api_instance.value;
  };

  const GetOrg = async (uid) => {
    const response = await default_api.get(`/organization/${uid}`);
    if (response.status === 200) {
      return response.data.organization;
    }
  };

  const GetOrganization = async (uid) => {
    org_promise.value = GetOrg(uid);
    org.value = await org_promise.value;
  };

  async function UpdateOrganization(org) {
    const local_api = await api();
    const response = await local_api.post(`/organization`, {organization: org});
    org.value = response.data.organization;
    Notify.create({
      color: 'positive',
      message: `Organization ${org.name} updated successfully`,
      icon: 'save'
    });
  }

  const GetPurchases = async (org) => {
    const response = await default_api.get(`/organization/${org.uid}/purchases`);
    if (response.status === 200) {
      return response.data;
    }
  };

  const MakePurchase = async (org, purchase) => {
    const api_instance = await api();
    const response = await api_instance.post(`/organization/${org.uid}/purchase`, {purchase: purchase});
    Notify.create({
      color: 'info',
      message: `Purchase for ${org.name}`,
      icon: 'shopping_cart'
    });
  };

  const DeleteOrganization = async (org) => {
    const api_instance = await api();
    const response = await api_instance.delete(`/organization/${org.uid}`);
    Notify.create({
      color: 'info',
      message: `Organization ${org.name} deleted successfully`,
      icon: 'delete'
    });
  };

  const GetOrgLoginOptions = async (subdomain) => {
    const response = await default_api.get(`/organization/${subdomain}/login_options`);
    if (response.status === 200) {
      org.value = response.data.organization;
      return response.data;
    }
  };

  const ParseSAML = async (metadata_url) => {
    const response = await default_api.post(`/parse_saml_metadata`, {metadata_url: metadata_url});
    if (response.status === 200) {
      return response.data;
    }
  };

  const UpdateProgram = async (program) => {
    const api_instance = await api();
    const response = await api_instance.post(`/organization/${org.value.uid}/program`, {program: program});
    Notify.create({
      color: 'positive',
      message: `Program ${response.data.program.program_name} updated successfully`,
      icon: 'save'
    });
    return response.data.program;
  };

  const DeleteProgram = async (program) => {
    const api_instance = await api();
    const response = await api_instance.delete(`/organization/${org.value.uid}/program/${program.uid}`);
    Notify.create({
      color: 'info',
      message: `Program ${program.program_name} deleted successfully`,
      icon: 'delete'
    });
  };

  const UpdateCampus = async (campus) => {
    const api_instance = await api();
    const response = await api_instance.post(`/organization/${org.value.uid}/campus`, {campus: campus});
    Notify.create({
      color: 'positive',
      message: `Campus ${response.data.campus.campus_name} updated successfully`,
      icon: 'save'
    });
    return response.data.campus;
  };

  const DeleteCampus = async (campus) => {
    const api_instance = await api();
    const response = await api_instance.delete(`/organization/${org.value.uid}/campus/${campus.uid}`);
    Notify.create({
      color: 'info',
      message: `Campus ${campus.campus_name} deleted successfully`,
      icon: 'delete'
    });
  };

  const GetProgram = async (program_uid) => {
    try {
      const api_instance = await api();
      const response = await api_instance.get(`/organization/${org.value.uid}/program/${program_uid}`);
      if (response.status === 200) {
        return response.data.program;
      }
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
      throw error;
    }
  };

  const GetCampus = async (campus_uid) => {
    try {
      const api_instance = await api();
      const response = await api_instance.get(`/organization/${org.value.uid}/campus/${campus_uid}`);
      if (response.status === 200) {
        return response.data.campus;
      }
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
      throw error;
    }
  };

  const GetCampuses = async (page, rowsPerPage, sortBy, descending, filter) => {
    try {
      const api_instance = await api();
      const response = await api_instance.get(`/organization/${org.value.uid}/campuses`, {
        params: {
          page,
          rowsPerPage,
          sortBy,
          descending,
          filter
        }
      });
      if (response.status === 200) {
        return response.data.campuses;
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function GetPrograms(page, rowsPerPage, sortBy, descending, filter) {
    try {
        const api = await this.api();
        const response = await api.get(`/organization/${this.org.uid}/programs`, {params:
                {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
        if (response.status === 200) {
            this.org.programs = response.data.programs;
            return response.data.programs;
        }
    }
    catch (error) {
        if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        throw error;
    }
 }

  async function GetCourses(organization, program_uid, campus_uid, page, rowsPerPage, sortBy, descending, filter, show_old) {
      try{
          const api = await this.api();
          const response = await api.get(`/courses`, {params:
                  {
                      organization_uid: organization,
                      campus_uid: campus_uid,
                      program_uid: program_uid,
                      query: filter,
                      page: page,
                      rowsPerPage: rowsPerPage,
                      sortBy: sortBy,
                      descending: descending,
                      show_old: show_old
                  }});
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function get_product_prices_for_user(){
      const api = await this.api();
      const response= await api.get('/user/product_prices');
      if(response.status === 200) {
          return response.data;
      }
  }

  async function GetTemplateCourses(page, rowsPerPage, sortBy, descending, filter, filtering) {
      try{
          const api = await this.api();
          const response = await api.get(`/template_courses`, {params:
                  {
                      query: filter,
                      page: page,
                      rowsPerPage: rowsPerPage,
                      sortBy: sortBy,
                      descending: descending,
                      filtering: filtering
                  }});
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetActivities(course, page, rowsPerPage, sortBy, descending, filter) {
      try {
          const api = await this.api();
          const response = await api.get(`/activities`, {params:
                  {course_uid: course, query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetTemplateActivities(page, rowsPerPage, sortBy, descending, filter, filtering, course_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/template_activities`, {params:
                  {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, filtering: filtering, course_uid: course_uid}});
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function UpdateCourse(course){
      const api = await this.api();
      const response = await api.post(`/course`, {course: course});
      Notify.create({
        color: 'positive',
        message: `Course ${response.data.course.course_name} updated successfully`,
        icon: 'save'
      })

      return response.data;
  }

  async function DeleteCourse(course){
      const api = await this.api();
      const response = await api.delete(`/course/${course.uid}`)
      Notify.create({
        color: 'info',
        message: `Course ${course.course_name} deleted successfully`,
        icon: 'delete'
      })
  }

  async function UpdateActivity(activity, msg){
      const api = await this.api();
      const response = await api.post(`/activity`, {activity: activity})
      Notify.create({
        color: 'positive',
        message: msg ? msg: `Activity ${response.data.activity.activity_name} updated successfully`,
        icon: 'save'
      })
      return response.data.activity;
  }

  async function DeleteActivity(activity){
      const api = await this.api();
      const response = await api.delete(`/course/${activity.course_uid}/activity/${activity.uid}`)
      Notify.create({
        color: 'info',
        message: `Activity ${activity.activity_name} deleted successfully`,
        icon: 'delete'
      })

  }

  async function GetCourse(course_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/course/${course_uid}`);
          if (response.status === 200) {
              return response.data.course;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetActivity(course_uid, activity_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/course/${course_uid}/activity/${activity_uid}`);
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function CopyActivity(activity_uid){
      const api = await this.api();
      const response = await api.post(`/copy_template_activity`, {activity_uid: activity_uid})
      Notify.create({
        color: 'positive',
        message: `Activity ${response.data.activity.activity_name} copied successfully`,
        icon: 'save'
      })
      return response.data.activity;
  }

  async function CopyCourse(course_uid){
      const api = await this.api();
      const response = await api.post(`/copy_template_course`, {course_uid: course_uid})
      Notify.create({
        color: 'positive',
        message: `Course ${response.data.course.course_name} copied successfully`,
        icon: 'save'
      })
      return response.data.course;
  }

  async function MakeTemplateActivity(activity, course_uid){
      const api = await this.api();
      const response = await api.post(`/make_template_activity/${course_uid}/${activity.uid}`)
      Notify.create({
        color: 'positive',
        message: `Activity ${response.data.activity.activity_name} made into template successfully`,
        icon: 'save'
      })
      return response.data.activity;
  }
  async function MakeTemplateCourse(course){
      const api = await this.api();
      const response = await api.post(`/make_template_course/${course.uid}`)
      Notify.create({
        color: 'positive',
        message: `Course ${response.data.course.course_name} made into template successfully`,
        icon: 'save'
      })
      return response.data.course;
  }

  async function AddCourse(course){
      const api = await this.api();
      const response = await api.post(`/add_template_course/${course.uid}`)
      Notify.create({
        color: 'positive',
        message: `Course ${response.data.course.course_name} added successfully`,
        icon: 'save'
      })
      return response.data;
  }

  async function GetStudentActivity(course_uid, activity_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/course/${course_uid}/student_activity/${activity_uid}`);
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetStudentSubmissions(course_uid, activity_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/course/${course_uid}/activity/${activity_uid}/submissions`);
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetSimulationData(course_uid, activity_uid) {
      try {
          const api = await this.api();
          const response = await api.get(`/course/${course_uid}/activity/${activity_uid}/simulation_data`);
          if (response.status === 200) {
              return response.data;
          }
      }
      catch (error) {
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
          throw error;
      }
  }

  async function GetStudentDashboardActivities(){
      const api = await this.api();
      const response = await api.get(`/student_dashboard_activities`);

      return response.data;
  }

  async function AddOrInviteUsers(users, role, entity){
      let api;
      if(entity.entity_type === 'organization'){
          api = default_api;
      }
      else{
          api = await this.api();
      }
      try{
          const response = await api.post('/add_invite_users', {users: users, role: role, entity: entity});
          if(response.status === 200) {
              Notify.create({
                color: 'positive',
                message: `Added/Invited ${response.data.users.length} users successfully`,
                icon: 'save'
              })

              return response.data.users;
          }
      }
      catch(error){
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
      }
  }

  async function RemoveUser(user_uid, entity){
      let api;
      if(entity.entity_type === 'organization'){
          api = default_api;
      }
      else{
          api = await this.api();
      }
      try{
          const response = await api.post('/remove_user', {user_uid: user_uid, entity: entity});
          if(response.status === 200) {
              Notify.create({
                color: 'positive',
                message: `Removed user from ${entity.name} successfully`,
                icon: 'save'
              })

              return response.data
          }
      }
      catch(error){
          if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
      }
  }

  async function GetRecycleBin(page, rowsPerPage, sortBy, descending, filter){
      const api = await this.api();
      const response = await api.get('/recycle_bin', {params:
                  {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});

      return response.data;
  }

  async function Undelete(obj){
      const api = await this.api();
      const response = await api.post('/undelete', {obj: obj});

      Notify.create({
        color: 'positive',
        message: `Undelete successful`,
      })
      return response.data;
  }

  async function GetPolicies(entity_type, entity_uid, page, rowsPerPage, sortBy, descending, filter){
      const api = await this.api();
      const response = await api.get(`/policies/${entity_type}/${entity_uid}`, {params:
                  {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending}});

      return response.data;
  }

  async function GetProducts() {
      const api = await this.api();
      const response = await api.get(`/products`);

      return response.data;
  }

  async function GetPrices(product_id, length) {
      const api = await this.api();
      const response = await api.get(`/prices/${product_id}`,
          {params: {length: length}});

      return response.data;
  }

  async function GetPolicy(entity_type, entity_uid, policy_uid){
      const api = await this.api();
      const response = await api.get(`/policy/${entity_type}/${entity_uid}`);

      return response.data.policy;
  }

  function CheckPolicy(course, level){
      let values = [];
      if(course.campus_embed && course.campus_embed.policy){
          if(level === 'simulation' && course.campus_embed.policy.product === 'basic'){
              values.push(false);
          }
          else if(level === 'advanced' && ['basic', 'simulation'].includes(course.campus_embed.policy.product)){
              values.push(false);
          }
          else{
              values.push(true);
          }
      }
      else if(course.program_embed && course.program_embed.policy){
          if(level === 'simulation' && course.program_embed.policy.product === 'basic'){
              values.push(false);
          }
          else if(level === 'advanced' && ['basic', 'simulation'].includes(course.program_embed.policy.product)){
              values.push(false);
          }
          else{
              values.push(true);
          }
      }

      if(values.some(v => v === false)){
          return false;
      }
      if(this.org.policy){
          if(level === 'simulation' && this.org.policy.product === 'basic'){
              return false;
          }
          else if(level === 'advanced' && ['basic', 'simulation'].includes(this.org.policy.product)){
              return false;
          }
      }
      return true
  }

  async function StartSimulation(activity, sim_start_time, sim_name){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/start`,
          {sim_start_time: sim_start_time, timezone_offset: new Date().getTimezoneOffset(), sim_name});

      Notify.create({
        color: 'positive',
        message: `Simulation Started`,
      })
      return response.data;
  }

  async function ResetSimulation(activity, simulation_run_uid){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/reset`,
          {});

      Notify.create({
        color: 'info',
        message: `Simulation Reset`,
      })
      return response.data;
  }

  async function PauseSimulation(activity, simulation_run_uid){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/pause`,
          {})

      Notify.create({
        color: 'info',
        message: `Simulation Paused`,
      })
      return response.data;
  }

  async function ResumeSimulation(activity, simulation_run_uid){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/resume`,
          {})

      Notify.create({
        color: 'positive',
        message: `Simulation Resumed`,
      })
      return response.data;
  }

  async function TimeWarpSimulation(activity, simulation_run_uid, time_amount){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${activity.course_uid}/activity/${activity.uid}/${simulation_run_uid}/time_warp`,
          {time_amount: time_amount})

      Notify.create({
        color: 'info',
        message: `Simulation Time Warped`,
      })
      return response.data;
  }

  async function GetSimulationRuns(course_uid, activity_uid){
      const api = await this.api();
      const response = await api.get(`/simulation/course/${course_uid}/activity/${activity_uid}/simulation_runs`);

      return response.data;
  }

  async function GetSimulationUsers(course_uid, activity_uid, simulation_run_uid){
      const api = await this.api();
      const response = await api.get(`/simulation/course/${course_uid}/activity/${activity_uid}/${simulation_run_uid}/users`);

      return response.data;
  }

  async function AddSimulationUsers(course_uid, activity_uid, simulation_run_uid, users){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${course_uid}/activity/${activity_uid}/${simulation_run_uid}/add_users`, {users: users});
      Notify.create({
        color: 'positive',
        message: `Added ${users.length} users successfully`,
        icon: 'save'
      })
      return response.data.users;
  }

  async function RemoveSimulationUser(course_uid, activity_uid, simulation_run_uid, user_uid){
      const api = await this.api();
      const response = await api.post(`/simulation/course/${course_uid}/activity/${activity_uid}/${simulation_run_uid}/remove_user`, {user_uid: user_uid});
      Notify.create({
        color: 'info',
        message: `Removed user successfully`,
        icon: 'save'
      })
      return response.data;
  }

  async function AddDevice(device) {
      const api = await this.api();
      const response = await api.post('/add_device', device);
      if (response.status === 200) {
          Notify.create({
              color: 'positive',
              message: `Device ${response.data.device.name} added successfully`,
              icon: 'save'
          });
          return response.data.device;
      }
  }

  async function GetDevices(page, rowsPerPage, sortBy, descending, filter, user_uid, organization_uid) {
      const api = await this.api();
      const response = await api.get('/get_devices', {params:
              {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, user_uid: user_uid, organization_uid: organization_uid}});
      if (response.status === 200) {
          return response.data;
      }
  }

  async function GetDevicesByActivity(page, rowsPerPage, sortBy, descending, filter, activity_uid) {
    const api = await this.api();
    const response = await api.get(`/devices_by_activity`, {params:
                {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, activity_uid: activity_uid}});

    if (response.status === 200) {
      return response.data;
    }
  }

  async function UpdateDevice(device){
    const api = await this.api();
    const response = await api.post('/device',{device: device});

    if (response.status === 200) {
      Notify.create({
        color: 'positive',
        message: `Device ${response.data.device.name} updated successfully`,
        icon: 'save'
      });
      return response.data;
    }
  }

  async function AddDevicesActivity(course_uid, activity_uid, devices){
    const api = await this.api();
    const response = await api.post(`/course/${course_uid}/activity/${activity_uid}/add_devices`, {devices: devices})

    if (response.status === 200) {
      Notify.create({
        color: 'positive',
        message: `Devices added successfully`,
        icon: 'save'
      });
      return response.data;
    }
  }

  async function DeleteDevice(device_id){
    const api = await this.api();
    try {
      const response = await api.delete(`/device/${device_id}`)
      Notify.create({
        color: 'info',
        message: `Device ${response.data.device.name} deleted successfully`,
        icon: 'delete'
      })
    }
    catch (error) {
      if(error.status === 404){
        console.log('Device not found');
      }
      else{
        throw error;
      }
    }
  }

  return {
    org,
    api_instance,
    api_region,
    session_promise,
    org_promise,
    activity,
    course,
    session,
    api,
    GetOrg,
    GetOrganization,
    UpdateOrganization,
    GetPurchases,
    MakePurchase,
    DeleteOrganization,
    GetOrgLoginOptions,
    ParseSAML,
    UpdateProgram,
    DeleteProgram,
    UpdateCampus,
    DeleteCampus,
    GetProgram,
    GetCampus,
    GetCampuses,
    GetPrograms,
    GetCourses,
    get_product_prices_for_user,
    GetTemplateCourses,
    GetActivities,
    GetTemplateActivities,
    UpdateCourse,
    DeleteCourse,
    UpdateActivity,
    DeleteActivity,
    GetCourse,
    GetActivity,
    CopyActivity,
    CopyCourse,
    MakeTemplateActivity,
    MakeTemplateCourse,
    AddCourse,
    GetStudentActivity,
    GetStudentSubmissions,
    GetSimulationData,
    GetStudentDashboardActivities,
    AddOrInviteUsers,
    RemoveUser,
    GetRecycleBin,
    Undelete,
    GetPolicies,
    GetProducts,
    GetPrices,
    GetPolicy,
    CheckPolicy,
    StartSimulation,
    ResetSimulation,
    PauseSimulation,
    ResumeSimulation,
    TimeWarpSimulation,
    GetSimulationRuns,
    GetSimulationUsers,
    AddSimulationUsers,
    RemoveSimulationUser,
    AddDevice,
    GetDevices,
    GetDevicesByActivity,
    UpdateDevice,
    AddDevicesActivity,
    DeleteDevice
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRegionStore, import.meta.hot))
}
