import { datadogRum } from '@datadog/browser-rum'
import { createRouter, createWebHistory } from 'vue-router'
// Layout components
const LoggedIn = () => import("../layouts/LoggedIn.vue");
const Default = () => import("../layouts/Default.vue");
const ComputedLayout = () => import("../layouts/ComputedLayout.vue");
const StudentLayout = () => import("../layouts/StudentLayout.vue");

// Main views
const DashboardView = () => import("../views/DashboardView.vue");
const Login = () => import("../views/Login.vue");
const DemoAccountView = () => import("../views/DemoAccountView.vue");
const StudentPayment = () => import("../views/StudentPayment.vue");
const Verification = () => import("../views/Verification.vue");
const RequestPasswordReset = () => import("../views/RequestPasswordReset.vue");
const Features = () => import("../views/Features.vue");
const Pricing = () => import("../views/Pricing.vue");
const ContactUs = () => import("../views/ContactUs.vue");
const SignUp = () => import("../views/SignUp.vue");
const Profile = () => import("../views/Profile.vue");
const NewPassword = () => import("../views/NewPassword.vue");
const OrganizationView = () => import("../views/OrganizationView.vue");
const Logout = () => import("../views/Logout.vue");
const BookstorePurchase = () => import("../views/BookstorePurchase.vue");

// Course related
const Courses = () => import("../views/Courses.vue");
const Course = () => import("../views/Course.vue");
const Activity = () => import("../views/Activity.vue");

// Flowsheet related
const Flowsheets = () => import("../views/Flowsheets.vue");
const FlowsheetBuilder = () => import("../views/FlowsheetBuilder.vue");

// Student related
const StudentActivity = () => import("../views/student/StudentActivity.vue");
const StudentDashboard = () => import("../views/student/StudentDashboard.vue");
const StudentActivities = () => import("../views/student/StudentActivities.vue");

// Patient related
const PatientTemplates = () => import("../views/patient/PatientTemplates.vue");
const PatientDetails = () => import("../views/patient/PatientDetails.vue");

// Chart related
const Charts = () => import("../views/Charts.vue");
const Versions = () => import("../views/Versions.vue");
const Version = () => import("../views/Version.vue");
const Chart = () => import("../views/Chart.vue");

// Other views
const Campus = () => import("../views/Campus.vue");
const Program = () => import("../views/Program.vue");
const Policy = () => import("../views/Policy.vue");

// Components
const AdvancedEdit = () => import("../components/flowsheets/AdvancedEdit.vue");

// Keep this as is since it's likely a module with route configurations
import { patient_routes } from "./patient";
// Keep store import as is since it might be needed for initialization
import { useUserStore } from "../stores/user";
import { useRegionStore} from "stores/region";
import { storeToRefs } from "pinia";


const SchoolsView = () => import('../views/SchoolsView.vue');
const UsersView = () => import('../views/UsersView.vue');
const HandleUpload = () => import('../views/HandleUpload.vue');
const SimControlPanel = () => import('../views/SimControlPanel.vue');
const StripePayment = () => import('../views/StripePayment.vue');
const Tour = () => import('../views/Tour.vue');
const Devices = () => import('../views/Devices.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'marketing',
      component: () => import('src/layouts/Marketing.vue'),
      children: [
        {
          path: '',
          name: 'home_view',
          component: () => import('src/views/IndexPage.vue')
        },
        {
          path: '/contact',
          name: 'contact',
          component: ContactUs
        },
        {
          path: '/tour',
          name: 'tour',
          component: Tour
        }
      ]
    },
    {
      path: '/',
      name: 'home',
      component: Default,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login
        },
        {
          path: 'demo',
          name: 'demo',
          component: DemoAccountView,
          props: function(route) {
              return {
                  verify: route.query.verify,
                  email: route.query.email
              }
          }
        },
        {
          path: 'student_pay',
          name: 'student_pay',
          component: StudentPayment,
        },
        {
          path: 'stripe_payment',
          name: 'stripe_payment',
          component: StripePayment,
          props: function(route) {
              return {
                  price_id: route.query.price_id,
              }
          }
        },
        {
          path: 'payment_complete',
          name: 'payment_complete',
          component: () => import('../views/PaymentComplete.vue'),
          props: function(route) {
              return {
                  session_id: route.query.session_id,
              }
          }
        },
        {
          path:'verification',
          name:'verification',
          component: Verification,
          props: function(route) {
              return {
                  verify: route.query.verify,
                  email: route.query.email
              }
          }
        },
        {
          path: 'request_reset',
          name: 'request_reset',
          component: RequestPasswordReset
        },
        {
          path: '/logout',
          name: 'logout',
          component: Logout,
          meta: {
            requiresAuth: true,
            requiresLicense: false
          }
        },
        {
          path: 'features',
          name: 'features',
          component: Features
        },
        {
          path: 'pricing',
          name: 'pricing,',
          component: Pricing
        },
        {
          path: 'signup',
          name: 'signup',
          component: SignUp,
          props: function(route) {
              return {
                  verify: route.query.verify,
                  email: route.query.email
              }
          }
        },
        {
            path: 'privacy',
            name: 'privacy',
            component: () => import('../views/Privacy.vue')
        },
        {
          path: 'terms',
          name: 'terms',
          component: () => import('../views/Terms.vue')
        }
      ]
    },
    {
      path: '/profile',
      name: 'computedlayout',
      meta: { requiresAuth: true},
      component: ComputedLayout,
      children: [
        {
          path: '',
          name: 'profile',
          component: Profile
        },
        {
            path: '/undelete',
            name: 'undelete',
            component: () => import('../views/Undelete.vue')
        },
        {
          path: '/new_password',
          name: 'new_password',
          component: NewPassword
        },
        {
          path: '/template_patients',
          name: 'template_patients',
          component: PatientTemplates
        },
        {
          path: '/template_activities',
          name: 'template_activities',
          component: () => import('../views/ActivityTemplates.vue')
        },
        {
          path: '/template_courses',
          name: 'template_courses',
          component: () => import('../views/CourseTemplates.vue')
        },
        {
          path: '/new_patient',
          name: 'new_patient',
          component: PatientDetails,
          props: function(route) {
              return {
                  template: route.query.template,
                  is_new: true
              }
          }
        },
        {
          path: '/organization/:organization_uid/new_campus',
          name: 'new_campus',
          component: Campus,
          props: function(route) {
              return {
                  is_new : true,
                  organization_uid: route.params.organization_uid
              }
          }
        },
        {
          path: '/organization/:organization_uid/campus/:campus_uid',
          name: 'campus',
          component: Campus,
          props: function(route) {
              return {
                  is_new : false,
                  organization_uid: route.params.organization_uid,
                  campus_uid: route.params.campus_uid
              }
          }
        },
        {
          path: '/organization/:organization_uid/new_program',
          name: 'new_program',
          component: Program,
          props: function(route) {
              return {
                  is_new : true,
                  organization_uid: route.params.organization_uid
              }
          }
        },
        {
          path: '/organization/:organization_uid/program/:program_uid',
          name: 'program',
          component: Program,
          props: function(route) {
              return {
                  is_new : false,
                  organization_uid: route.params.organization_uid,
                  program_uid: route.params.program_uid
              }
          },
        },
        {
            path: '/organization/:organization_uid/purchase_new',
            name: 'purchase_new',
            component: BookstorePurchase,
            props: function(route) {
                return {
                    organization_uid: route.params.organization_uid,
                    is_new: true
                }
            }
        },
        {
          path: '/policy/:entity_type/:entity_uid/new_policy',
          name: 'new_policy',
          component: Policy,
          props: function(route) {
              return {
                  is_new : true,
                  entity_type: route.params.entity_type,
                  entity_uid: route.params.entity_uid
              }
          }
        },
        {
          path: '/policy/:entity_type/:entity_uid/:policy_uid',
          name: 'policy',
          component: Policy,
          props: function(route) {
              return {
                  is_new : false,
                  entity_type: route.params.entity_type,
                  entity_uid: route.params.entity_uid,
                  policy_uid: route.params.policy_uid
              }
          },
        },
        {
          path: '/handle_upload',
          name: 'handle_upload',
          component: HandleUpload,
          props: function(route) {
              return {
                  url: route.query.url,
                  entity_type: route.query.entity_type,
                  entity_uid: route.query.entity_uid,
                  role: route.query.role
              }
          },
        }
      ]
    },
    {
      path: '/student',
      name: 'student',
      component: StudentLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'student_dashboard',
          component: StudentDashboard
        },
        {
          path: 'course/:course_uid/activities',
          name: 'course_activities',
          component: StudentActivities,
          props: true
        },
        {
          path: 'course/:course_uid/activity/:activity_uid',
          name: 'student_activity',
          component: StudentActivity,
          props: true
        },
        {
          path: 'course/:course_uid/activity/:activity_uid/create_patient',
          name: 'create_patient',
          component: PatientDetails,
          props: function(route) {
              return {
                  is_new : true,
                  course_uid: route.params.course_uid,
                  activity_uid: route.params.activity_uid,
                  name: route.query.name,
                  template_patient_id: route.query.template_patient_id
              }
          }
        }
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard_group',
      component: ComputedLayout,
      meta: { requiresAuth: true},
      children: [
        {
          path: '',
          name: 'dashboard',
          component: DashboardView
        },
        {
          path: '/courses',
          name: 'courses',
          component: Courses,
        },
        {
          path: '/course/:course_uid',
          name: 'course',
          component: Course,
          props: function(route) {
              return {
                    course_uid: route.params.course_uid,
                  added: route.query.added
              }
          }
        },
        {
          path: '/course_new',
          name: 'course_new',
          component: Course,
          props: function(route) {
              return {
                  is_new : true,
                  organization_uid: route.query.organization_uid,
                  program_uid: route.query.program_uid,
                  campus_uid: route.query.campus_uid,
                  template: route.query.template
              }
          }
        },
        {
          path: '/new_activity',
          name: 'new_activity',
          component: Activity,
          props: function(route) {
              return {
                  is_new : true,
                  course_uid: route.query.course_uid,
                  template: route.query.template
              }
          }
        },
        {
          path: '/course/:course_uid/activity/:uid',
          name: 'activity',
          component: Activity,
          props: true
        },
        {
          path: '/devices',
          name: 'devices',
          component: Devices
        }
      ]
    },
    {
      path: '/superuser',
      name: 'superuser',
      component: ComputedLayout,
      meta: { requiresAuth: true, superuser: true},
      children: [
        {
          path: '/user',
          name: 'users',
          component: UsersView
        },
        {
          path: '/user/:uid',
          name: 'user',
          component: Profile,
          props: true
        },
        {
          path: '/organization',
          name: 'organizations',
          component: SchoolsView
        },
        {
          path: '/organization/:uid',
          name: 'Manage Organization',
          component: OrganizationView,
          props: function (route) {
            return {
              organization_uid: route.params.uid,
              is_new: false
            }
          }
        },
        {
          path: '/organization_new',
          name: 'New Organization',
          component: OrganizationView,
          props: function(route) {
              return {
                  bookstore: route.query.bookstore,
                  is_new: true,
                  organization_uid: null
              }
          }
        },
        {
          path: '/flowsheets',
          name: 'Flowsheets',
          component: Flowsheets
        },
        {
          path: '/lookups',
          name: 'LookupTypes',
          component: () => import('../views/LookupTypes.vue')
        },
        {
          path: '/lookups/:flowsheet_uid/:lookup_type',
          name: 'Lookups',
          component: () => import('src/views/Lookups.vue'),
          props: function(route) {
                return {
                    flowsheet_uid: route.params.flowsheet_uid,
                    lookup_type_uid: route.params.lookup_type,
                    version: route.query.version,
                    from: route.query.from
                }
          }
        },
        {
          path: '/charts',
          name: 'charts',
          component: Charts
        },
        {
          path: '/versions',
          name: 'Versions',
          component: Versions
        },
        {
          path: '/new_version',
          name: 'New Version',
          component: Version,
          props: function(route) {
              return {
                  version_uid: 'new',
              }
          }
        },
        {
          path: '/version/:version_uid',
          name: 'Edit Version',
          component: Version,
          props: true,
        },
        {
          path: '/new_chart',
          name: 'New Chart',
          component: Chart,
          props: function(route) {
              return {
                  chart_uid: 'new',
                  version: route.query.version,
              }
          }
        },
        {
          path: '/chart/:version/:chart_uid',
          name: 'Edit Chart',
          component: Chart,
          props: function(route) {
            return {
              chart_uid: route.params.chart_uid,
              version: route.params.version,
              from: route.query.from
            }
          }
        },
      ]
    },
    {
      path: '/flowsheet_builder/:version/:flowsheet_uid',
      name: 'flowsheet_builder',
      component: FlowsheetBuilder,
      props: function(route) {
          return {
              flowsheet_uid: route.params.flowsheet_uid,
              version: route.params.version,
              from: route.query.from
        }
      }
    },
    {
      path: '/flowsheet_builder/:version/:flowsheet_uid/advanced/:field_uid',
      name: 'flowsheet_builder_advanced',
      component: AdvancedEdit,
      props: function(route) {
          return {
              flowsheet_uid: route.params.flowsheet_uid,
              version: route.params.version,
              field_uid: route.params.field_uid,
              from: route.query.from
        }
      }
    },
    {
      path: '/flowsheet_builder/:version/:flowsheet_uid/advanced/:field_uid/action/:action_uid',
      name: 'flowsheet_builder_action',
      component: AdvancedEdit,
      props: function(route) {
          return {
              flowsheet_uid: route.params.flowsheet_uid,
              version: route.params.version,
              field_uid: route.params.field_uid,
              action_uid: route.params.action_uid,
              from: route.query.from
        }
      }
    },
    {
      path: '/lookups/:version/:flowsheet_uid/advanced/:lookup_type/action/:action_uid',
      name: 'lookup_builder__action',
      component: AdvancedEdit,
      props: function(route) {
          return {
              flowsheet_uid: route.params.flowsheet_uid,
              version: route.params.version,
              lookup_type: route.params.lookup_type,
              action_uid: route.params.action_uid,
              from: route.query.from
        }
      }
    },
    {
      path: '/new_flowsheet',
      name: 'new_flowsheet',
      component: FlowsheetBuilder,
      props: function(route) {
          return {
              flowsheet_uid: 'new',
              version: route.query.version,
        }
      }
    },
    {
        path: '/sim',
        name: 'sim',
        component: LoggedIn,
        meta: { requiresAuth: true},
        children: [
            {
                path: 'course/:course_uid/activity/:uid',
                name: 'sim_control_panel',
                component: SimControlPanel,
                props: true
            }

        ]
    },
    ...patient_routes
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return {
            el: to.hash,
            behavior: 'smooth'
        }
      }
      return { top: 0 }
  }
},
})

router.beforeEach(async(to, from, next) => {
  const userStore = useUserStore();
  const regionStore = useRegionStore();
  const { org } = storeToRefs(regionStore);

  datadogRum.setUser({
    id: userStore.user ? userStore.user.uid : null,
    name: userStore.user ? userStore.user.username : null,
    orgname: org.value ? org.value.name : null,
    org_uid: org.value ? org.value.uid : null
  });

  if((userStore.loggedIn || to.query.logged_in) && !userStore.user) {
    const result = await userStore.getUserSession();
    if (result !== true) {
      next({path: '/login', query: {redirect_to: to.fullPath}})
      return
    }
  }

  if(to.path === '/'){
    if(userStore.loggedIn) {
      next({path: userStore.default_route})
      return;
    }
    else{
      if(to.path === '/'  && !(window.location.hostname.startsWith(import.meta.env.VITE_STAGE) || window.location.hostname.startsWith('www'))) {
          let subdomain = window.location.hostname.split('.')[0];
          if (!['www', 'chartflow', 'dev', 'mike-local'].includes(subdomain)) {
              next({path: '/login'})
              return;
          }
      }
    }
  }


  if(to.matched.some(record => record.meta.requiresAuth) && !userStore.loggedIn) {
    next({path: '/login', query: {redirect_to: to.fullPath}})
    return;
  } else {
    if(to.matched.some(record => record.meta.requiresAuth) && to.matched.some(record => record.meta.superuser) && !userStore.user.roles in ["Superuser"]) {
      next(userStore.default_route)
      return;
    } else {
      if(org.value && org.value.billing_level!=='Free' && to.matched.some(record => record.meta.requiresAuth && record.meta.requiresLicense !== false) && userStore.default_route === '/student') {
          if(!userStore.check_license()){
              next('/student_pay')
              return
          }
      }
      next()
    }
  }
});

router.afterEach((to) => {
  //If we need special page view monitoring
})


export default router
