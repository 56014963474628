//https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec
//https://axios-http.com/docs/interceptors

import { useRegionStore } from "./region";
import { useUserStore } from "./user";
import { useRoute, useRouter } from "vue-router";
import { Notify } from "quasar";
import {storeToRefs} from "pinia";

export function useRestapi() {
  const regionStore = useRegionStore();
  const { org } = storeToRefs(regionStore)
  const userStore = useUserStore();
  const { user, loggedIn } = storeToRefs(userStore);
  const route = useRoute();
  const router = useRouter();

  function intercept(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }

  function error_intercept(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // all the other error responses
    console.log("Error Inteceptor: error", error);
    if(error && error.response) {
      switch (error.response.status) {
        case 400:
          if(error.response.data && error.response.data.message) {
            Notify.create({
              color: 'negative',
              message: error.response.data.message,
              icon: 'report_problem'
            });
          }
          break;
        case 401: // authentication error, logout the user
          console.log("Error Inteceptor: 401 error");
          org.value = null;
          user.value = null;
          loggedIn.value = false;
          localStorage.removeItem('logged_in');
          if (window.location.pathname === '/login') {
            return Promise.reject(error);
          }
          router.push('/login?redirect_to=' + route.fullPath);
          break;
        case 404:
          if(error.response.data && error.response.data.message) {
            Notify.create({
              color: 'negative',
              message: error.response.data.message,
              icon: 'report_problem'
            });
          }
          break;
        case 500:
          console.log("Error Inteceptor: 500 error");
          Notify.create({
            color: 'negative',
            html: true,
            message: 'There was an error that was automatically reported to the development team.<br>Please refresh your page and try again.<br>If this continues to be an issue, please email <a href="mailto=support@chartflow.io" target="_blank">support@chartflow.io</a>',
            icon: 'report_problem'
          });
          break;
        default:
      }
    }
    return Promise.reject(error);
  }

  return {
    intercept,
    error_intercept,
  }
}
