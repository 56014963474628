<script>
import { storeToRefs } from 'pinia'
import { useUserStore } from "./stores/user";
import { useRegionStore } from "./stores/region";
import {onMounted, ref} from 'vue'
import { useRoute } from 'vue-router'
import { provide } from 'vue'
import {Cookies, useQuasar} from 'quasar'

export default {
  setup () {
    const route = useRoute();
    const leftDrawerOpen = ref(false)
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { user } = storeToRefs(userStore)
    const { org } = storeToRefs(regionStore)
    const current_device_id = ref(null);

    const $q = useQuasar();

    provide('leftDrawerOpen', leftDrawerOpen);

    onMounted(() => {
      current_device_id.value = Cookies.get('device_id');
    });

    return {
      route,
      current_device_id,
      userStore,
      user,
      org,
      leftDrawerOpen,
      toggleLeftDrawer () {
        leftDrawerOpen.value = !leftDrawerOpen.value
      },
      forget_device(){
        $q.loading.show();
        regionStore.DeleteDevice(current_device_id.value).then(() => {
          current_device_id.value = null
        }).finally(() => {
          $q.loading.hide()
        })
      }

    }
  },
}
</script>

<template>
<q-layout view="hHh lpR fFf">

    <q-header elevated class="bg-white text-black print-hide" >
      <q-toolbar class="q-px-xs-sm">
        <q-btn v-if="user && (userStore.default_route!=='/student' || $route.path.startsWith('/patient'))" class="lt-md" dense flat round icon="menu" @click="toggleLeftDrawer" />

        <q-toolbar-title v-if="$q.screen.lt.md && route.fullPath.startsWith('/patient')" class="q-px-xs">
          <router-link class="block row" :to="userStore.default_route">
            <img alt="Chartflow Logo" height="50" src="/icon-blue.svg" class="q-mt-md"><br>
          </router-link>
        </q-toolbar-title>
        <q-toolbar-title v-else class="col-grow">
          <router-link class="block row" :to="userStore.default_route">
            <img alt="Chartflow Logo" height="30" src="/logo.svg" class="q-mt-md q-pl-sm"><br>
          </router-link>
          <router-link class="block row" style="margin-top: -15px;" v-if="org" :to="(user && userStore.check_roles(['Superuser', 'Support'])) ? `/organization/${org.uid}` : userStore.default_route">
            <span class="q-pl-sm q-mb-sm text-primary text-caption">
              <q-avatar v-if="org.logo" size="xs">
                <img :src="org.logo" alt="">
              </q-avatar>
              {{ org.name }}
              {{ user && userStore.check_roles(['Superuser', 'Support']) ? `(${org.region})` : '' }}
            </span>
          </router-link>
        </q-toolbar-title>
        <div class="row items-center" id="toolbar_container">

        </div>
        <div class="gt-sm q-ml-xl col row justify-center" v-if="!user">
          <q-btn color="primary" flat to="/#features" class=text-bold>Features</q-btn>
          <q-btn color="primary" flat to="/#pricing" class=text-bold>Pricing</q-btn>
          <q-btn-dropdown color="primary" stretch flat label="Resources" class=text-bold>
            <q-list>
              <q-item clickable v-close-popup href="https://blog.chartflow.io" target="_blank">
                <q-item-section>
                  <q-item-label>Blog</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup href="https://publishing.chartflow.io/" target="_blank">
                <q-item-section>
                  <q-item-label>Publishing</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup href="https://help.chartflow.io" target="_blank">
                <q-item-section>
                  <q-item-label>Help Center</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-btn color="primary" flat to="/contact" class=text-bold>Contact Us</q-btn>
        </div>
        <q-space />
        <div class="col col-shrink">
          <q-btn class="gt-sm text-bold" v-if="!user" color="primary" flat to="/signup">New Student Account</q-btn>
          <q-btn v-if="!user" color="secondary" to="/login" class="text-bold">Login</q-btn>
          <q-avatar class="gt-sm" v-if="user" color="primary">
              <span class="text-body2 text-white" v-if="!user.picture">{{ user.initials }}</span>
              <img v-else :src="user.picture" />
          </q-avatar>
          <q-btn-dropdown class="gt-sm" v-if="user" color="primary" stretch flat :label="user.username">
            <q-list>
              <q-item clickable v-close-popup to="/profile">
                <q-item-section>
                  <q-item-label>Profile</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="userStore.default_route!=='/student'" clickable v-close-popup to="/undelete">
                <q-item-section>
                  <q-item-label>Recycle Bin</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="userStore.default_route!=='/student' && current_device_id" clickable v-close-popup @click="forget_device()">
                <q-item-section>
                  <q-item-label>Forget Device</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="userStore.default_route!=='/student'" clickable v-close-popup to="/devices">
                <q-item-section>
                  <q-item-label>Devices</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup to="/logout">
                <q-item-section>
                  <q-item-label>Log Out</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-btn-dropdown class="gt-sm" v-if="user" stretch flat label="Support" icon="help_outline" color="secondary">
            <q-list>
              <q-item clickable v-close-popup href="https://help.chartflow.io" target="_blank">
                <q-item-section>
                  <q-item-label>Help Center</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="userStore.check_roles(['Superuser', 'Support', 'Instructor', 'Organization Admin', 'Program Admin', 'Campus Admin', 'Adjunct'], org.uid)"
                clickable
                v-close-popup
                href="https://resources.chartflow.io"
                target="_blank"
              >
                <q-item-section>
                  <q-item-label>Instructor Resources</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup href="https://help.chartflow.io/articles/new-order" target="_blank">
                <q-item-section>
                  <q-item-label>New Order Request</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
      <q-toolbar class="lt-md">
        <q-btn-dropdown v-if="!user" color="primary" stretch flat label="Resources">
          <q-list>
            <q-item clickable v-close-popup href="https://blog.chartflow.io" target="_blank">
              <q-item-section>
                <q-item-label>Blog</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup href="https://publishing.chartflow.io/" target="_blank">
              <q-item-section>
                <q-item-label>Publishing</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup href="https://help.chartflow.io" target="_blank">
              <q-item-section>
                <q-item-label>Help Center</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn v-if="!user" color="primary" flat to="/signup">New Student Account</q-btn>
        <q-avatar v-if="user" color="primary">
            <span class="text-body2" v-if="!user.picture">{{ user.initials }}</span>
            <img v-else :src="user.picture" />
        </q-avatar>
        <q-btn-dropdown v-if="user" color="primary" stretch flat :label="user.username">
          <q-list>
            <q-item clickable v-close-popup to="/profile">
              <q-item-section>
                <q-item-label>Profile</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="userStore.default_route!=='/student'" clickable v-close-popup to="/undelete">
              <q-item-section>
                <q-item-label>Recycle Bin</q-item-label>
              </q-item-section>
            </q-item>
              <q-item v-if="userStore.default_route!=='/student' && current_device_id" clickable v-close-popup @click="forget_device()">
                <q-item-section>
                  <q-item-label>Forget Device</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="userStore.default_route!=='/student'" clickable v-close-popup to="/devices">
                <q-item-section>
                  <q-item-label>Devices</q-item-label>
                </q-item-section>
              </q-item>
            <q-item clickable v-close-popup to="/logout">
              <q-item-section>
                <q-item-label>Log Out</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn-dropdown v-if="user" stretch flat label="Support" icon="help_outline" color="secondary">
          <q-list>
            <q-item clickable v-close-popup href="https://help.chartflow.io" target="_blank">
              <q-item-section>
                <q-item-label>Help Center</q-item-label>
              </q-item-section>
            </q-item>
              <q-item
                v-if="userStore.check_roles(['Superuser', 'Support', 'Instructor', 'Organization Admin', 'Program Admin', 'Campus Admin', 'Adjunct'], org.uid)"
                clickable
                v-close-popup
                href="https://resources.chartflow.io"
                target="_blank"
              >
                <q-item-section>
                  <q-item-label>Instructor Resources</q-item-label>
                </q-item-section>
              </q-item>
            <q-item clickable v-close-popup href="https://help.chartflow.io/articles/new-order" target="_blank">
              <q-item-section>
                <q-item-label>New Order Request</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <router-view></router-view>

  </q-layout>
</template>

<style lang="scss">
.q-btn {
  text-transform: none;
}

.text-body2 {
  color: #707070
}

.q-table th {
    background-color: $primary;
    color: white;
    font-weight: bold;
}

.q-table thead tr:first-child th {
  top: 0;
}

.q-table thead tr th {
  position: sticky;
  z-index: 1;
}

.q-table tr:nth-child(odd) {
    background-color: $grey-3;
}

.q-table tr:nth-child(even) {
    background-color: #ffffff;
}

.q-table--loading thead tr:last-child th {
  /* height of all previous header rows */
  top: 48px
}

a:link {
  text-decoration: none;
}

@media print {
  .print-hide, aside {
    display:none !important;
  }
  .q-page-container {
    padding: 0px !important;
  }
}

</style>
