import {defineStore, acceptHMRUpdate, storeToRefs} from 'pinia'
import {ref, computed} from 'vue';
import axios from "axios";
import {useRegionStore} from "./region";
import {useRestapi} from "./restapi";
import {intersection} from "../util";
import {Loading, Notify, useQuasar} from 'quasar'
import { date } from 'quasar'

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const loggedIn = ref(localStorage.getItem('logged_in'));
  const device = ref(null);
  const passkey_abort_controller = ref(null);
  const regionStore = useRegionStore();
  const { session_promise, org } = storeToRefs(regionStore);

  const restapi = useRestapi();

  const $q = useQuasar()

  axios.defaults.withCredentials = true

  const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  api.interceptors.response.use(restapi.intercept, restapi.error_intercept);

  const api_no_intercept = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const org_for_user = computed(() => {
    if (!user.value) return null;
    for (let o of user.value.schools) {
      if (o.organization_uid === org.value.uid) return o;
    }
  });

  const default_route = computed(() => {
    if (!user.value) return '/';
    if (intersection(['Superuser', 'Support'], user.value.roles).length) return '/user';
    if (intersection(['Content Manager'], user.value.roles).length) return '/template_patients';
    let org = org_for_user.value;
    if (org) {
      if (intersection(['Instructor', 'Organization Admin', 'Organization Content Admin', 'Program Admin', 'Campus Admin', 'Program Content Admin'], org.roles).length) return '/dashboard';
    } else if (intersection(['Demo'], user.value.roles).length) return '/demo';
    return '/student'
  });

  const manage_content = computed(() => {
    if (!user.value) return false;
    return intersection(['Superuser', 'Content Manager'], user.value.roles).length > 0;
  });

  const check_roles = (roles, organization_uid, program_uid, campus_uid, course_uid) => {
    let has_roles = [];
    if (user.value.roles && user.value.roles.length > 0) {
      has_roles.push(...user.value.roles);
    }
    const school = user.value.schools.find(school => school.organization_uid === organization_uid);
    let program = null;
    let course = null;
    let campus = null;
    if (school) {
      program = school.programs.find(program => program.program_uid === program_uid);
      campus = school.campuses.find(campus => campus.campus_uid === campus_uid);
      course = school.courses.find(course => course.uid === course_uid);
      has_roles.push(...intersection(school.roles, ['Organization Admin', 'Organization Content Admin']));
      if(intersection(roles, ['Instructor', 'Adjunct']).length > 0){
        has_roles.push(...intersection(school.roles, ['Instructor', 'Adjunct']));
      }
    }
    if (program) {
      has_roles.push(...intersection(program.roles, ['Program Admin', 'Program Content Admin']));
      if(intersection(roles, ['Instructor', 'Adjunct']).length > 0){
        has_roles.push(...intersection(program.roles, ['Instructor', 'Adjunct']));
      }
    }
    if (campus) {
      has_roles.push(...intersection(campus.roles, ['Campus Admin']));
      if(intersection(roles, ['Instructor', 'Adjunct']).length > 0){
        has_roles.push(...intersection(campus.roles, ['Instructor', 'Adjunct']));
      }
    }
    if (course) {
      has_roles.push(...course.roles);
    }
    return intersection(roles, has_roles).length > 0;
  };

  const check_license = () => {
    let current_date = date.addToDate(new Date(), {minutes: 5})
    for (let license of user.value.licenses) {
      if (new Date(license.start_date) <= current_date && new Date(license.end_date) >= new Date()) return true;
    }
    return false;
  };

  const programs_for_organization = (organization_uid) => {
    const school = user.value.schools.find(school => school.organization_uid === organization_uid);
    if (!school) return [];
    return school.programs;
  };

  const get_user_program = (organization_uid, program_uid) => {
    const school = user.value.schools.find(school => school.organization_uid === organization_uid);
    if (!school) return null;
    return school.programs.find(program => program.program_uid === program_uid);
  };

  const get_user_campus = (organization_uid, campus_uid) => {
    const school = user.value.schools.find(school => school.organization_uid === organization_uid);
    if (!school) return null;
    return school.campuses.find(campus => campus.campus_uid === campus_uid);
  };

  const available_roles = (organization_uid, program_uid, campus_uid, course_uid) => {
    const school = user.value.schools.find(school => school.organization_uid === organization_uid);
    let program = null;
    let course = null;
    let campus = null;
    if (school) {
      program = school.programs.find(program => program.program_uid === program_uid);
      campus = school.campuses.find(campus => campus.campus_uid === campus_uid);
      course = school.courses.find(course => course.uid === course_uid);
    }
    let potential_roles = [];
    if (course_uid) {
      potential_roles = ['Student', 'Instructor', 'Adjunct']
      if (intersection(user.value.roles, ['Superuser', 'Support'])) return potential_roles;
      if (school && 'Organization Admin' in school.roles) return potential_roles;
      if (program && 'Program Admin' in program.roles) return potential_roles;
      if (campus && 'Campus Admin' in campus.roles) return potential_roles;
      if (course && 'Instructor' in course.roles) return potential_roles;
    } else if (program_uid) {
      potential_roles = ['Student', 'Instructor', 'Adjunct', 'Program Admin', 'Program Content Admin']
      if (intersection(user.value.roles, ['Superuser', 'Support'])) return potential_roles;
      if (school && 'Organization Admin' in school.roles) return potential_roles;
      if (program && 'Program Admin' in program.roles) return potential_roles;
      if (program && 'Program Content Admin' in program.roles) return ['Program Content Admin'];
    } else if (campus_uid) {
      potential_roles = ['Student', 'Instructor', 'Adjunct', 'Campus Admin']
      if (intersection(user.value.roles, ['Superuser', 'Support'])) return potential_roles;
      if (school && 'Organization Admin' in school.roles) return potential_roles;
      if (campus && 'Campus Admin' in campus.roles) return potential_roles;
    } else if (organization_uid) {
      potential_roles = ['Student', 'Instructor', 'Adjunct', 'Organization Admin', 'Organization Content Admin', 'Billing']
      if (intersection(user.value.roles, ['Superuser', 'Support'])) return potential_roles;
      if (school && 'Organization Admin' in school.roles) return potential_roles;
      if (school && 'Organization Content Admin' in school.roles) return ['Organization Content Admin'];
      if (school && 'Billing' in school.roles) return ['Billing'];
    } else {
      if (intersection(user.value.roles, ['Superuser', 'Support'])) {
        return ['Superuser', 'Content Manager', 'Support'];
      }
      if ('Content Manager' in user.value.roles) {
        return ['Content Manager'];
      }
    }

    return null;
  };

  const set_session = (session) => {
    user.value = session.user;
    loggedIn.value = true;
    localStorage.setItem('logged_in', true);
    if (session.organization) {
      org.value = session.organization;
    }
  };

    async function get_checkout_session(cart){
        const response= await api.post('/user/checkout_session', {cart: cart});
        if(response.status === 200) {
            return response.data;
        }
    }

    async function get_session_status(session_id){
        const response= await api.get(`/user/session_status/${session_id}`);
        if(response.status === 200) {
            return response.data;
        }
    }

    async function CheckUser(email, course_code, demo) {
        try {
            const response= await api_no_intercept.post('/login_check', {email: email, demo: demo, course_code: course_code});
            if (response.status === 200) {
                return response.data;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return {message: error.response.data.message};
        }
    }

    async function CreateCardUser(email, user, fingerprint, card_name, course_code){
        try {
            const response = await api_no_intercept.post('/create_card_user', {email: email, user: user, fingerprint: fingerprint, card_name: card_name, course_code: course_code});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return {success: true, redirect: response.data.redirect};
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return {success: false, message: error.response.data.message};
        }
    }

    async function VerifyUser(email, verification_code) {
        try {
            const response = await api_no_intercept.post('/verify_email', {email: email, verification_code: verification_code});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function GetOrganizations(page, rowsPerPage, sortBy, descending, filter, show_orgs_only) {
        const response = await api.get('/get_organizations', {params:
                {query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy, descending: descending, show_orgs_only: show_orgs_only}});
        if (response.status === 200) {
            return response.data;
        }
    }

    async function GetUsers(organization_uid, campus_uid, program_uid, course_uid, page, rowsPerPage, sortBy, descending, filter, students) {
        const response = await api.get('/users', {params:
                {organization_uid: organization_uid, campus_uid: campus_uid, program_uid: program_uid,
                    course_uid: course_uid, query: filter, page: page, rowsPerPage: rowsPerPage, sortBy: sortBy,
                    descending: descending, students: students}});
        if (response.status === 200) {
            return response.data;
        }
    }

    async function UpdateUser(u, new_demo){
        const response = await api.post('/update_user', {user: u, new_demo: new_demo ? new_demo: null});
        if (response.status === 200) {
            if (!user.value || u.uid === user.value.uid) {
                user.value = response.data.user;
                if(response.data.organization){
                    org.value = response.data.organization;
                }
            }
            Notify.create({
              color: 'positive',
              message: `User ${response.data.user.username} updated successfully`,
              icon: 'save'
            })

            return response.data.user;
        }
    }

    async function ContactUs(new_demo){
        try {
            const response = await api.post('/contact_us', {new_demo: new_demo ? new_demo: null});
            if (response.status === 200) {
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function DeleteUser(user){
        const response = await api.delete(`/user/${user.uid}`);
    }

    async function Login(email, password, fingerprint) {
        try {
            const response = await api_no_intercept.post('/login', {email: email, password: password, fingerprint: fingerprint});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return {success: true, redirect: response.data.redirect};
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return {success: false, message: error.response.data.message};
            localStorage.removeItem('logged_in');
        }
    }

    async function BecomeUser(uid) {
        try {
            const response = await api_no_intercept.post(`/become_user/${uid}`);
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function GetUploadUrl(file_name, file_type) {
        try {
            const response = await api.post('/get_upload_url', {file_name: file_name, file_type: file_type});
            if (response.status === 200) {
                return response.data;
            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function Logout() {
        try {
            user.value = null;
            const response = await api_no_intercept.post('/logout');
            if (response.status === 200) {
                if(response.data.session) {
                    this.set_session(response.data.session);
                }
                else {
                    localStorage.removeItem('logged_in');
                    org.value = null;
                    user.value = null;
                    loggedIn.value = false;
                }

            }
        }
        catch (error) {
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
            localStorage.removeItem('logged_in');
            loggedIn.value = false;
        }
    }

    async function CreateOTP(email, redirect_to) {
        try {
            const response = await api_no_intercept.post('/create_otp', {email: email, redirect_to: redirect_to});
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    }

    async function VerifyOTP(email, otp) {
        try {
            const response = await api_no_intercept.post('/verify_otp', {email: email, otp: otp});
            if (response.status === 200) {
                this.set_session(response.data.session);
                return true;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    }

    async function getUserSession() {
        try {
            session_promise.value = regionStore.session();
            const r = await regionStore.session_promise;
            localStorage.setItem('logged_in', true)
            loggedIn.value = true;
            user.value = r.user;
            device.value = r.device;
            if(user.value) return true;
        } catch (error) {
            loggedIn.value = false;
            localStorage.removeItem('logged_in');
            user.value = null;
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
            throw error;
        }
    }

    async function UpdateSession(org_id){
        const response = await api.post('/user_session', {organization_uid: org_id});
    }

    async function getUser(uid) {
        try {
            const response = await api.get(`/user/${uid}`);
            if (response.status === 200) {
                return response.data.user;
            }
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
        }
    }

    async function NewPassword(password, confirm_password, user_uid){
        try{
            const response = await api.post('/new_password', {password: password, user_uid: user_uid});
            if(response.status === 200) {
                Notify.create({
                  color: 'positive',
                  message: `User ${user.value.username} password updated successfully`,
                  icon: 'save'
                })
                return true;

            }
        }
        catch(error){
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function AddCourseCode(course_code){
        try{
            const response = await api.post('/add_course_code', {course_code: course_code});
            if(response.status === 200) {
                user.value = response.data.user;
                Notify.create({
                  color: 'positive',
                  message: `User ${user.value.username} successfully added to course`,
                  icon: 'save'
                })

                return response.data.user;
            }
        }
        catch(error){
            Notify.create({
              color: 'negative',
              message: `Course not found for code ${course_code}`,
              icon: 'error'
            })
            if(error.response && error.response.status >= 400 && error.response.status < 500 ) return error.response.data.message;
        }
    }

    async function ApplyPaymentCode(payment_code){
        try {
            const response = await api.post('/apply_payment_code', {payment_code: payment_code});
            user.value = response.data.user;
            return true;
        } catch (error) {
            if (error.response && error.response.status >= 400 && error.response.status < 500) return error.response.data.message;
            throw error;
        }
    }

    async function GetUserData(url){
        const response = await api.get('/handle_users_data', {params: {url: url}});
        if(response.status === 200) {
            return response.data;
        }
    }

    async function AddEmail(user, email){
      const response = await api.post('/add_email', {uid: user.uid, email: email});
      if(response.status === 200) {
          return response.data;
      }
    }

    async function AddCard(user, fingerprint, name){
        const response = await api.post('/add_card', {uid: user.uid, fingerprint: fingerprint, name: name});
        if(response.status === 200) {
            return response.data;
        }
    }

    async function DeleteEmail(user, email, system){
        const response = await api.post('/delete_email', {uid: user.uid, system: system || 'email', system_key: email});
        if(response.status === 200) {
            return response.data;
        }
    }

    function base64urlToArrayBuffer(base64url) {
      // Convert Base64URL to standard Base64
      const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');

      // Decode Base64 string to raw binary string
      const binaryString = atob(base64);

      // Convert binary string to ArrayBuffer
      const len = binaryString.length;
      const buffer = new ArrayBuffer(len);
      const byteArray = new Uint8Array(buffer);

      for (let i = 0; i < len; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      return buffer;
    }
    // Register a new passkey
    async function registerPasskey() {
        const results = await api.post('/auth/register-options');
        const options = JSON.parse(results.data.options);
        options['challenge'] = base64urlToArrayBuffer(options['challenge']);
        options['user']['id'] = base64urlToArrayBuffer(options['user']['id']);
        if (options['excludeCredentials']) {
            for (let i = 0; i < options['excludeCredentials'].length; i++) {
                options['excludeCredentials'][i]['id'] = base64urlToArrayBuffer(options['excludeCredentials'][i]['id']);
            }
        }
        console.log(options)
        try {
          const credential = await navigator.credentials.create({publicKey: options});
          const response = await api.post('/auth/register', {
              credential: credential,
          });

          return response.data
        }
        catch (error) {
          if(error.name === 'InvalidStateError'){
            Notify.create({
              type: 'info',
              message: 'This passkey is already registered with your account.',
            });
          }
          else{
            throw error;
          }
        }
    }

    // Authenticate with a passkey
    async function loginWithPasskey(conditional) {
      passkey_abort_controller.value = new AbortController();
      $q.loading.show();
      const results = await api.post('/auth/login-options')
      const options = JSON.parse(results.data.options);
      options['challenge'] = base64urlToArrayBuffer(options['challenge']);
      $q.loading.hide();
      const assertion = await navigator.credentials.get({
        publicKey: options,
        mediation: conditional ? 'conditional' : undefined,
        signal: passkey_abort_controller.value.signal
      });
      $q.loading.show();

      const response = await api.post('/auth/login', {
        credentials: JSON.stringify(assertion),
      });
      $q.loading.hide();
      this.set_session(response.data.session);
      return {success: true, redirect: response.data.redirect};
    }

    async function passkey_register_complete() {
        const response = await api.post('/auth/register-complete');
        return response.json();
    }

    async function passkey_login_complete() {
        const response = await api.post('/auth/login-complete');
        return response.json();
    }

  return {
    user,
    passkey_abort_controller,
    loggedIn,
    org_for_user,
    default_route,
    manage_content,
    check_roles,
    check_license,
    programs_for_organization,
    get_user_program,
    get_user_campus,
    available_roles,
    set_session,
    get_checkout_session,
    get_session_status,
    CheckUser,
    CreateCardUser,
    VerifyUser,
    GetOrganizations,
    GetUsers,
    UpdateUser,
    ContactUs,
    DeleteUser,
    Login,
    BecomeUser,
    GetUploadUrl,
    Logout,
    CreateOTP,
    VerifyOTP,
    getUserSession,
    UpdateSession,
    getUser,
    NewPassword,
    AddCourseCode,
    ApplyPaymentCode,
    GetUserData,
    AddEmail,
    AddCard,
    DeleteEmail,
    registerPasskey,
    loginWithPasskey,
    passkey_register_complete,
    passkey_login_complete
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
